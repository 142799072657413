import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'
import {Link} from "react-router-dom"

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import download from '../../util/Download'
import {
  CommissioningSessionInterface,
  FilterCommissioningInterface
} from '../../../../domain/Commissioning/CommissioningList'
import CommissioningGateway from '../../../../gateway/Commissioning/CommissioningGateway'

import Pagination from '../Pagination/Pagination'
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";

import '../../../../assets/styles/components/_datalist.scss'
import CommissioningListPresenter
  from "../../../../presenter/Commissioning/CommissioningListPresenter";
import {setOpenDatalistFilterCommissioning} from "../../../store/component/event";
import ReactTooltip from "react-tooltip";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";
import TableHead from "../Table/TableHead";
import {ListRequest} from "../../../../useCase/Commissioning/List/ListRequest";
import ListUseCase from "../../../../useCase/Commissioning/List/ListUseCase";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {setCountTotal} from "../../../store/component/product";
import {CallerType} from "../../../../domain/Caller/Caller";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callerType?: CallerType,
}

const CommissioningDatalist: FunctionComponent<Props> = ({callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning?.actions)
  const referential: ReferentielInterface | null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterCommissioning = useAppSelector((state) => state.event.openDatalistFilterCommissioning)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const {register, handleSubmit, watch, setValue} = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterCommissioning.filters)
      const commissionings = new ListUseCase(new CommissioningGateway())
        .execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder, callerType)
        .then(response => {
          return response
        });
      const presenter = new CommissioningListPresenter(commissionings);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('name', openDatalistFilterCommissioning.filters.name)
      setValue('keywords', openDatalistFilterCommissioning.filters.keywords)
    }

  }, [currentPage, openDatalistFilterCommissioning.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterCommissioning.filters])


  const onSubmit: SubmitHandler<FilterCommissioningInterface> = data => {
    dispatch(setOpenDatalistFilterCommissioning({
        show: false,
        count: openDatalistFilterCommissioning.count,
        filters: {
          name: data.name,
          type: data.type,
          keywords: data.keywords,
        }
      }
    ))
  }

  const paginate = (pageNumber: number) => {
    controller.abort();
    setCurrentPage(pageNumber)
  }

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterCommissioning({
        show: true,
        count: openDatalistFilterCommissioning.count,
        filters: openDatalistFilterCommissioning.filters
      }))
    }
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterCommissioning({
        show: false,
        count: 0,
        filters: {
          name: '',
          type: '',
          keywords: '',
        }
      }
    ))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new CommissioningGateway().getExport(openDatalistFilterCommissioning.filters).then(response => {
      if (response) {
        download(t('export.commissionings'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
      {(viewModel !== null &&
          <>
              <div className={`datalist`}>
                  <div className="datalist__title">{t(viewModel.title)}</div>
                  <div className="datalist__header flex">
                      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex justify-between items-center">
                          <div className="filter__input">
                            {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                              <div key={uuidV4()} className="input-no-border u-mb0 ">
                                <img src={iconHeadingSearch} alt=""/>
                                <input {...register(filter.field)}
                                       placeholder={t('common.search-by', {keyword: t(filter.keyword)})}
                                       className="u-mxs"/>
                              </div>
                            ))}
                          </div>
                          <div className="filter__actions">
                              <button type="submit" className="button button--submit">{t('search.submit')}</button>
                              <button type="button" className="button button--white"
                                      onClick={() => resetFilters()}>{t('search.cancel')}</button>
                          </div>
                      </form>
                    {/* <HeaderRight numberOfActivatedFilters={openDatalistFilterCommissioning.count}
                        handleClickFilter={handleClickFilter}
                        handleClickExport={handleClickExport}
                        isLoadingExport={isLoadingExport}/> */}
                  </div>
                  <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                                name="numberRows"
                                label={t('filters.display-results-by')}
                                options={optionsNbRows}
                                register={register}
                                noChoiceOption
                  />
                  <div className="table-fix-head">
                      <table className="datalist__datas">
                          <thead>
                          {viewModel.heading && <TableHead typeFilter={"API"}
                                                           heading={viewModel.heading}
                                                           sortOrder={sortOrder}
                                                           setSortOrder={setSortOrder}
                                                           viewModel={viewModel}
                                                           setViewModel={setViewModel}
                                                           filter={openDatalistFilterCommissioning.filters}
                                                           watchNumberRows={watchNumberRows}
                                                           currentPage={currentPage}
                                                           listRequest={
                                                               new ListRequest(
                                                                 currentPage,
                                                                 watchNumberRows || 50,
                                                                 openDatalistFilterCommissioning.filters
                                                               )
                                                           }
                                                           listUseCase={ListUseCase}
                                                           listPresenter={CommissioningListPresenter}
                                                           gateway={CommissioningGateway}
                          />}
                          </thead>
                          <tbody>
                          {viewModel.data !== undefined &&
                            viewModel.data.map((item: CommissioningSessionInterface) => (
                              <tr key={item.id}>
                                <td>
                                  {commissioningRules?.read && <Link to={`/${t('url.commissioning.read-session')}/${item.type}/${item.id}`}
                                         target="_blank"
                                         rel="noopener noreferrer"
                                  >
                                    <button type="button" className="button-reset">
                                      <img src={iconSearch} alt=""/>
                                    </button>
                                  </Link>}
                                  {commissioningRules?.update && <Link to={`/${t('url.commissioning.edit-setting')}/${item.type}/${item.id}`}>
                                    <button type="button" className="button-reset">
                                      <img src={iconEdit} alt=""/>
                                    </button>
                                  </Link>}
                                </td>
                                <td>
                                  <div className="u-mys">
                                    {item.criterias?.transactions?.[0] && <div
                                        className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}>
                                        <div className="badge__container">
                                            <div
                                                className="badge__text">{item.criterias.transactions?.[0].acronyme}</div>
                                        </div>
                                    </div>
                                    }

                                    {
                                      item.criterias.products && item.criterias.products.map((product: any, index: number) => {
                                        if (index < 3) {
                                          return <div
                                            className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}
                                            key={uuidV4()}>
                                            <div className="badge__container">
                                              <div className="badge__text">{product.label}</div>
                                            </div>
                                          </div>
                                        }
                                      })}
                                    {
                                      item.criterias.products && item.criterias.products.length > 3 ? (
                                        <div
                                          className={`badge badge--min badge--without-cta badge--partner-product-more u-mrs u-mbs`}>
                                          <div className="badge__container">
                                            <ReactTooltip id="more"/>
                                            <a className="badge__text"
                                               data-tip=""
                                               data-for={`more-${item.id}`}
                                            >{`+${item.criterias.products.length - 3}`}
                                            </a>
                                            <ReactTooltip id={`more-${item.id}`} place="right" effect="solid">
                                              {
                                                item.criterias.products.map((product: any, index: number) => {
                                                  if (index > 2) {
                                                    return <span key={index}>{product.label} <br/></span>
                                                  }
                                                })
                                              }
                                            </ReactTooltip>
                                          </div>
                                        </div>) : null
                                    }
                                  </div>
                                </td>
                                <td className="capitalize">{item.type}</td>
                                <td>{item.code}</td>
                                <td>{`${item.period?.start || ""} ${item.period?.start ? "au" : ""} ${item?.period?.end || ""}`}</td>
                                <td>{item.paymentScheduledAt}</td>
                                <td className="capitalize">{item.criteriaType}</td>
                                <td className="capitalize">
                                  {referential?.commission?.status?.find(status => status.value === item.status)?.label}
                                </td>
                              </tr>
                            ))}
                          {viewModel.data === undefined || viewModel.data.length === 0 &&
                              <tr>
                                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                              </tr>
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
              <Pagination currentPage={currentPage} itemsPerPage={viewModel.pagination.itemsPerPage}
                          numberOfItems={viewModel.pagination.numberOfItems} callback={paginate}/>
          </>
      )}
    </>
  )
}

export default CommissioningDatalist
