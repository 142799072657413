class PartnerDropdownPresenter {
  present(data: {partnerId: string, name: string}[]): {value: string, label: string}[] {
    return data.map((partner) => {
      return {
        value: partner.partnerId,
        label: partner.name
      }
    })
  }
}

export default PartnerDropdownPresenter
