class InvestorDropdownPresenter {
  present(data: {accountId: string, name: string}[]): {value: string, label: string}[] {
    return data.map((investor) => {
      return {
        value: investor.accountId,
        label: investor.name
      }
    })
  }
}

export default InvestorDropdownPresenter
