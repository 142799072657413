import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import SelectCustom from '../../Elements/Select'
import CheckboxToggle from '../../Elements/CheckboxToggle'
import { useCreateSearchMandateAutoForm } from './useCreateSearchMandateAutoForm'
import getClassForOverlay from '../../../util/Sidebar'

type Props = {
  partnerOptions: { value: string; label: string }[]
  investorOptions: { value: string; label: string }[]
}

const CreateSearchMandateAuto: FunctionComponent<Props> = ({ partnerOptions, investorOptions }) => {
  const { t } = useTranslation()
  const {
    register,
    errors,
    handleSubmit,
    onConfirm,
    handleClose,
    openCreateSearchMandateAuto
  } = useCreateSearchMandateAutoForm()

  return (
    <>
      <div
        className={`overlay ${getClassForOverlay(openCreateSearchMandateAuto.show)}`}
        onClick={handleClose}
      />
      <div
        className={`sidebar sidebar--right ${openCreateSearchMandateAuto.show ? 'sidebar--active' : ''}`}
      >
        <form onSubmit={handleSubmit(onConfirm)} className='form-bloc form-bloc--partner-address-form'>
          <div className='sidebar__content'>
            <div className='title'>{t('dismemberment-ledger.create-mandate-auto.title')}</div>
            <div className='form-bloc__form flex-container'>
              <SelectCustom
                classes='col-md-6'
                id='investorId'
                name='investorId'
                options={investorOptions}
                register={register}
                label={t('dismemberment-ledger.create-mandate-auto.choice-investor')}
                errorMessage={errors.investorId?.message}
              />
              <SelectCustom
                classes='col-md-6'
                id='partnerId'
                name='partnerId'
                options={partnerOptions}
                register={register}
                label={t('dismemberment-ledger.create-mandate-auto.choice-partner')}
                errorMessage={errors.partnerId?.message}
              />
              <CheckboxToggle
                classes='col-md-12'
                id='isMultipleMandates'
                name='isMultipleMandates'
                register={register}
                label={t('dismemberment-ledger.create-mandate-auto.multiple-mandates')}
              />
              <footer className='sidebar__footer'>
                <button type='submit' className='button button--ink-2 u-mrm'>
                  {t('common.save')}
                </button>
                <button
                  type='button'
                  className='button button--ink-2 button--ink-2--outline'
                  onClick={handleClose}
                >
                  {t('common.cancel')}
                </button>
              </footer>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default CreateSearchMandateAuto
