import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { setOpenCreateSearchMandateAuto } from '../../../../store/component/event'
import { useDismembermentLedger } from '../../../Context/DismembermentLedgerContext'
import blockScrollBody from '../../../util/BlockScroll'
import { usePrompt } from '../../../util/Navigation'
import DismembermentLedgerGateway from '../../../../../gateway/DismembermentLedger/DismembermentLedgerGateway'
import CreateSearchMandateAutoUseCase from '../../../../../useCase/DismembermentLedger/CreateSearchMandateAuto/CreateSearchMandateAutoUseCase'
import { createSearchMandateAutoSchema } from './schema-create-search-mandate-auto'
import { toastError, toastSuccess } from '../../../util/Toast'
import { isApiError } from '../../../../type/ApiError'

type CreateSearchMandateAuto = z.infer<typeof createSearchMandateAutoSchema>

export const useCreateSearchMandateAutoForm = () => {
  const dispatch = useAppDispatch()
  const { selectedBareOwners } = useDismembermentLedger()
  const openCreateSearchMandateAuto = useAppSelector(state => state.event.openCreateSearchMandateAuto)

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm<CreateSearchMandateAuto>({
    resolver: zodResolver(createSearchMandateAutoSchema),
  })

  useEffect(() => {
    blockScrollBody(openCreateSearchMandateAuto.show)
  }, [openCreateSearchMandateAuto])

  const onConfirm = async (data: CreateSearchMandateAuto) => {
    const request = {
      bareOwners: selectedBareOwners.map((row) => row.id),
      investorId: data.investorId,
      partnerId: data.partnerId,
      isMultipleMandates: data.isMultipleMandates,
    }

    const gateway = new DismembermentLedgerGateway()
    const useCase = new CreateSearchMandateAutoUseCase(gateway)

    try {
      const response = await useCase.execute(request);
      if (response) {
        reset(data);
        handleClose();
        toastSuccess('dismemberment-ledger.create-mandate-auto.notify.success');
      } else {
        toastError('dismemberment-ledger.create-mandate-auto.notify.error');
      }
    } catch (e) {
      if (isApiError(e) && e.code !== 500 && e.data?.errors[0]?.messages?.length) {
        toastError(e.data?.errors[0]?.messages[0])
      } else {
        toastError('dismemberment-ledger.create-mandate-auto.notify.error');
      }
    }
  }

  const handleClose = () => {
    reset()
    dispatch(setOpenCreateSearchMandateAuto({ show: false }))
  }

  const { isDirty } = useFormState({ control })
  usePrompt(isDirty, handleSubmit(onConfirm))

  return {
    register,
    errors,
    handleSubmit,
    onConfirm,
    handleClose,
    openCreateSearchMandateAuto,
  }
}
