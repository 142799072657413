import { CreateSearchMandateAutoRequestInterface } from './CreateSearchMandateAutoRequest'
import DismembermentLedgerGateway from '../../../gateway/DismembermentLedger/DismembermentLedgerGateway'

export default class CreateSearchMandateAutoUseCase {
  public dismembermentLedgerGateway

  constructor(DismembermentLedgerGateway: DismembermentLedgerGateway) {
    this.dismembermentLedgerGateway = DismembermentLedgerGateway
  }

  async execute(CreateSearchMandateAutoRequest: CreateSearchMandateAutoRequestInterface): Promise<string[] | null> {
    return await this.dismembermentLedgerGateway.createSearchMandateAuto(
      CreateSearchMandateAutoRequest.bareOwners,
      CreateSearchMandateAutoRequest.investorId,
      CreateSearchMandateAutoRequest.partnerId,
      CreateSearchMandateAutoRequest.isMultipleMandates
    ).then(response => response)
  }
}
