import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/movement'
import {
  setOpenDatalistFilterPreSubscription
} from '../../../store/component/event'
import {FilterPreSubscriptionInterface,} from '../../../../domain/PreSubscription/PreSubscriptionList'
import MovementListPresenter from '../../../../presenter/Movement/MovementListPresenter'
import {ListRequest} from '../../../../useCase/PreSubscription/List/ListRequest'
import ListUseCase from '../../../../useCase/PreSubscription/List/ListUseCase'
import PreSubscriptionGateway from '../../../../gateway/PreSubscription/PreSubscriptionGateway'
import Pagination from '../Pagination/Pagination'
import HeaderRight from './Element/HeaderRight'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import UserDatalist from "./UsersDatalist";
import {MovementListInterface} from "../../../../domain/Movement/MovementList";
import MovementUtil from '../../util/MovementUtil';
import TransationUtil from "../../../../domain/Utils/TransationUtil";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";
import MultiSelectCustom from "../Elements/MultiSelect";
import TableHead from "../Table/TableHead";
import downloadBlobFile from "../../util/DownloadBlobFile";
import {toastError} from "../../util/Toast";
import { getLabelByValue } from '../../util/ReferentialI18n'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  type: string
}

const PreSubscriptionDatalist: FunctionComponent<Props> = ({type}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterPreSubscription = useAppSelector((state) => state.event.openDatalistFilterPreSubscription)
  const subscriptionRules = useAppSelector((state) => state.me.me?.rules.subscription.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<string>(uuidV4());
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, control, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(setOpenDatalistFilterPreSubscription( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterPreSubscription.filters)

      const preSubscription = new ListUseCase(new PreSubscriptionGateway()).execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder).then(response => {
        return response
      })

      const presenter = new MovementListPresenter(preSubscription)
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('product', openDatalistFilterPreSubscription.filters.product)
      setValue('transactionType', openDatalistFilterPreSubscription.filters.transactionType)
      setValue('status', openDatalistFilterPreSubscription.filters.status)
      setValue('tags', openDatalistFilterPreSubscription.filters.tags)
      setValue('propertyType', openDatalistFilterPreSubscription.filters.propertyType)
      setValue('paymentMode', openDatalistFilterPreSubscription.filters.paymentMode)
      setValue('user', openDatalistFilterPreSubscription.filters.user)
      setValue('source', openDatalistFilterPreSubscription.filters.source)
    }

  }, [currentPage, openDatalistFilterPreSubscription.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterPreSubscription.filters])

  useEffect(() => {
    setCurrentPage(1)
    dispatch(setOpenDatalistFilterPreSubscription({
      show: false,
      count: openDatalistFilterPreSubscription.count,
      filters: {
        product: openDatalistFilterPreSubscription.filters.product,
        transactionType: openDatalistFilterPreSubscription.filters.transactionType,
        status: openDatalistFilterPreSubscription.filters.status,
        tags: openDatalistFilterPreSubscription.filters.tags,
        propertyType: openDatalistFilterPreSubscription.filters.propertyType,
        paymentMode: openDatalistFilterPreSubscription.filters.paymentMode,
        user: openDatalistFilterPreSubscription.filters.user,
        name: openDatalistFilterPreSubscription.filters.name,
        keywords: openDatalistFilterPreSubscription.filters.keywords,
        source: openDatalistFilterPreSubscription.filters.source
      }
    }))
  }, [type])

  const onSubmit: SubmitHandler<FilterPreSubscriptionInterface> = data => {
    dispatch(setOpenDatalistFilterPreSubscription({
      show: false,
      count: openDatalistFilterPreSubscription.count,
      filters: {
        product: data.product,
        transactionType: data.transactionType,
        status: data.status,
        tags: data.tags,
        propertyType: data.propertyType,
        paymentMode: data.paymentMode,
        user: data.user,
        name: data.name,
        keywords: data.keywords,
        source: data.source,
      }
    }))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterPreSubscription({show: true, count: openDatalistFilterPreSubscription.count, filters: openDatalistFilterPreSubscription.filters}))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(uuidV4())
    dispatch(setOpenDatalistFilterPreSubscription({show: false, count: openDatalistFilterPreSubscription.count, filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: '',
        source: ''
      }}))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new PreSubscriptionGateway().getExportAsBlob(openDatalistFilterPreSubscription.filters).then(response => {
      if (response) {
        downloadBlobFile(response, t('export.pre-subscriptions'))
        setLoadingExport(false)
      }
    }).catch(response => {
      if (!response.message?.includes(' status code 500') && response.blob) {
        toastError(response.blob)
      }
      else
        toastError(t('movement.pre-subscription.notify.export-error'))
    })
      .finally(() => {
        setLoadingExport(false)
      })
  }

  const getLink = (presubscription: MovementListInterface) => {
    const isEditable = TransationUtil.isEditable(presubscription) && subscriptionRules?.update

    return (
        <>
          {subscriptionRules?.read && <Link to={`/${t('url.pre-subscription.read')}/${presubscription.id}`}
                 target="_blank"
                 rel="noopener noreferrer"
          >
            <button type="button" className="button-reset">
              <img src={iconSearch} alt="search icon"/>
            </button>
          </Link>}
          {isEditable && <Link to={`/${t('url.pre-subscription.edit')}/${presubscription.id}`}>
            <button type="button" className="button-reset">
              <img src={iconEdit} alt="edit icon"/>
            </button>
          </Link>}
        </>
    )
  }

  return (
    <>
      {viewModel !== null && (
        <>
          <div className={`datalist`}>
            <div className='datalist__title'>{t(viewModel.title)}</div>
            <div className='datalist__header'>
              <form onSubmit={handleSubmit(onSubmit)} className='filter'>
                <div className='filter__input flex items-center'>
                  {viewModel.filtersShortcut.map((filter: { keyword: string; field: string; type: string }) => (
                    <div key={uuidV4()} className='input-no-border'>
                      <img src={iconHeadingSearch} alt='' />
                      <input
                        {...register(filter.field)}
                        placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
                        className='u-mxs'
                      />
                    </div>
                  ))}
                  <MultiSelectCustom
                    id='status'
                    name='status'
                    classes='u-mbs'
                    control={control}
                    label={t('filters.display-status')}
                    options={referential?.wallet.status || []}
                    customOnChange={options => {
                      setValue('status', options)
                    }}
                    defaultValue={openDatalistFilterPreSubscription.filters.status}
                    clearValues={clearMultiSelectValues}
                  />
                  <div className='form-control__input u-mbs'>
                    <UserDatalist control={control} name='user' />
                  </div>
                </div>
                <div className='filter__actions'>
                  <button type='submit' className='button button--submit'>
                    {t('search.submit')}
                  </button>
                  <button type='reset' className='button button--white' onClick={() => resetFilters()}>
                    {t('search.cancel')}
                  </button>
                </div>
              </form>
              <HeaderRight
                numberOfActivatedFilters={openDatalistFilterPreSubscription.count}
                handleClickFilter={handleClickFilter}
                handleClickExport={handleClickExport}
                isLoadingExport={isLoadingExport}
                allowExport={subscriptionRules?.export}
              />
            </div>
            <SelectCustom
              classes='flex justify-end u-mbs'
              id='numberRows'
              name='numberRows'
              label={t('filters.display-results-by')}
              options={optionsNbRows}
              register={register}
              noChoiceOption
            />
            <div className='table-fix-head'>
              <table className='datalist__datas'>
                <thead>
                  {viewModel.heading && (
                    <TableHead
                      typeFilter={'API'}
                      heading={viewModel.heading}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      viewModel={viewModel}
                      setViewModel={setViewModel}
                      filter={openDatalistFilterPreSubscription.filters}
                      watchNumberRows={watchNumberRows}
                      currentPage={currentPage}
                      listRequest={
                        new ListRequest(
                          currentPage,
                          watchNumberRows || 50,
                          openDatalistFilterPreSubscription.filters
                        )
                      }
                      listUseCase={ListUseCase}
                      listPresenter={MovementListPresenter}
                      gateway={PreSubscriptionGateway}
                    />
                  )}
                </thead>
                <tbody>
                  {viewModel.data !== undefined &&
                    viewModel.data.map((item: MovementListInterface) => (
                      <tr key={uuidV4()}>
                        <td className='col-action'>{getLink(item)}</td>
                        <td>{item.product_label}</td>
                        <td>{getLabelByValue(item.transaction_type ?? '', referential?.movement.transaction_type ?? [])}</td>
                        <td>{item.code}</td>
                        <td>{item.engagedAt}</td>
                        <td>{item.status_label}</td>
                        <td>{getLabelByValue(item.property_type ?? '', referential?.movement.property_type ?? [])}</td>
                        <td>{item.investor_id}</td>
                        <td>{item.investor_name}</td>
                        <td>{item.partner_code}</td>
                        <td>{item.partner_name}</td>
                        <td>
                          {item.user?.firstname?.toUpperCase().slice(0, 1)}. {item.user?.lastname?.toUpperCase()}
                        </td>
                        <td>{getLabelByValue(item.payment_method ?? '', referential?.wallet_subscription.payment_method ?? [])}</td>
                        <td>{getLabelByValue(item.payment_status ?? '', referential?.wallet_subscription.payment_status ?? [])}</td>
                        <td>{item.payment_date}</td>
                        <td>{item.share_count}</td>
                        <td>{item.total_amount}</td>
                        <td>
                          <div className='u-mys'>
                            {item.tags &&
                              item.tags.map(tag => {
                                const key = `movement.tags.${tag}`
                                const type = MovementUtil.getType(tag)
                                return (
                                  <div
                                    className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`}
                                    key={uuidV4()}
                                  >
                                    <div className='badge__container'>
                                      <div className='badge__text'>{t(key)}</div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </td>
                        <td>{getLabelByValue(item.source ?? '', referential?.transaction.source ?? [])}</td>
                        <td className="align-center">
                          <div className="checkbox-bool-container">
                            {item.particularity_recurrent ? (
                              <div className="checkbox-bool checked">✔</div>
                            ) : (
                              <div className="checkbox-bool">✖</div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  {viewModel.data === undefined ||
                    (viewModel.data.length === 0 && (
                      <tr>
                        <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
            numberOfItems={viewModel.pagination.numberOfItems}
            callback={paginate}
          />
        </>
      )}
    </>
  )
}

export default PreSubscriptionDatalist
