import React, { createContext, useCallback, useContext, useState } from 'react'

interface DismembermentLedgerContextType {
  selectedBareOwners: { id: string; duration: number }[]
  totalNbSharesBareOwners: number
  selectedUsufructuaries: { id: string; duration: number }[]
  totalNbSharesUsufructuaries: number
  updateSelection: (
    type: 'bare-owners' | 'usufructuaries',
    selectedRows: { id: string; duration: number }[],
    totalShares: number
  ) => void
  refresh: number
  forceRefresh: () => void
}

const DismembermentLedgerContext = createContext<DismembermentLedgerContextType>({
  selectedBareOwners: [],
  totalNbSharesBareOwners: 0,
  selectedUsufructuaries: [],
  totalNbSharesUsufructuaries: 0,
  updateSelection: () => {
    throw new Error('updateSelection must be used within a DismembermentLedgerProvider')
  },
  refresh: 0,
  forceRefresh: () => {
    throw new Error('forceRefresh must be used within a DismembermentLedgerProvider')
  },
})

export const useDismembermentLedger = (): DismembermentLedgerContextType => {
  const context = useContext(DismembermentLedgerContext)
  if (!context) {
    throw new Error('useDismembermentLedger must be used within a DismembermentLedgerProvider')
  }
  return context
}

export const DismembermentLedgerProvider: React.FC = ({ children }) => {
  const [selectedBareOwners, setSelectedBareOwners] = useState<{ id: string; duration: number }[]>([]);
  const [totalNbSharesBareOwners, setTotalNbSharesBareOwners] = useState<number>(0)
  const [selectedUsufructuaries, setSelectedUsufructuaries] = useState<{ id: string; duration: number }[]>([]);
  const [totalNbSharesUsufructuaries, setTotalNbSharesUsufructuaries] = useState<number>(0)
  const [refresh, setRefresh] = useState<number>(0)

  const updateSelection = (
    type: 'bare-owners' | 'usufructuaries',
    selectedRows: { id: string; duration: number }[],
    totalShares: number
  ) => {
    if (type === 'bare-owners') {
      setSelectedBareOwners(selectedRows);
      setTotalNbSharesBareOwners(totalShares);
    } else if (type === 'usufructuaries') {
      setSelectedUsufructuaries(selectedRows);
      setTotalNbSharesUsufructuaries(totalShares);
    }
  };


  const forceRefresh = useCallback(() => {
    console.log('forceRefresh')
    setSelectedBareOwners([])
    setTotalNbSharesBareOwners(0)
    setSelectedUsufructuaries([])
    setTotalNbSharesUsufructuaries(0)
    setRefresh((prev) => prev + 1)
  }, [])

  return (
    <DismembermentLedgerContext.Provider
      value={{
        selectedBareOwners,
        totalNbSharesBareOwners,
        selectedUsufructuaries,
        totalNbSharesUsufructuaries,
        updateSelection,
        refresh,
        forceRefresh,
      }}
    >
      {children}
    </DismembermentLedgerContext.Provider>
  )
}
