import { DismembermentLedgerListInterface } from '../../domain/DismembermentLedger/DismembermentLedgerList'
import { Presenter } from '../Presenter'

class DismembermentLedgerListPresenter extends Presenter {
  private useCase: {
    getDismembermentLedgers: { data: DismembermentLedgerListInterface[] | null; nb_element: number }
  }

  constructor(getDismembermentLedgers: any) {
    super({
      viewModel: {
        title: 'dismemberment-ledger.search',
        heading: [
          { name: 'dismemberment-ledger.heading.product', sort: 'product.acronyme' },
          { name: 'dismemberment-ledger.heading.invest-id', sort: 'account.account_code' },
          { name: 'dismemberment-ledger.heading.invest-fullname', sort: 'person.firstname' },
          { name: 'dismemberment-ledger.heading.nb-share', sort: 'transaction.share_count' },
          { name: 'dismemberment-ledger.heading.amount', sort: 'transaction.total_amount' },
          { name: 'dismemberment-ledger.heading.duration', sort: 'transaction.particularity_duration' },
          { name: 'dismemberment-ledger.heading.key', sort: 'investor.dismemberment_key' },
          { name: 'dismemberment-ledger.heading.partner-id', sort: 'partner.partner_code' },
          { name: 'dismemberment-ledger.heading.partner-name', sort: 'partner.social_reason' },
          { name: 'dismemberment-ledger.heading.execution', sort: 'mandat_execution' },
          { name: 'dismemberment-ledger.heading.fund_reception_timing', sort: 'mandat_fund_reception_timing' },
          { name: 'dismemberment-ledger.heading.created-at', sort: 'transaction.created_at' },
          { name: 'dismemberment-ledger.heading.start-at-mandate', sort: 'transaction.mandat_start_at' },
          { name: 'dismemberment-ledger.heading.end-at-mandate', sort: 'transaction.mandat_end_at' },
        ],
        data: [],
        filtersShortcut: [{ keyword: 'filters.by-keyword-or-id', field: 'keywords', type: 'input' }],
        filters: [],
        pagination: {
          itemsPerPage: 50,
          numberOfItems: 0,
        },
        count: 0,
      },
    })

    this.useCase = {
      getDismembermentLedgers,
    }
  }

  async load() {
    try {
      this._setDismembermentLedgers(await this.useCase.getDismembermentLedgers)
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setDismembermentLedgers(DismembermentLedgers: {
    data: DismembermentLedgerListInterface[] | null
    nb_element: number
  }) {
    this.update({
      data: DismembermentLedgers.data,
      pagination: {
        itemsPerPage: 50,
        numberOfItems: DismembermentLedgers.nb_element,
      },
    })
  }
}

export default DismembermentLedgerListPresenter
