import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'

import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { setOpenDatalistFilterDistributionSessionInvestor } from '../../../../store/component/event'
import { SessionInvestorListInterface, FilterSessionInvestorInterface } from '../../../../../domain/Distribution'
import Pagination from '../../Pagination/Pagination'
import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import TableHead from "../../Table/TableHead"
import NumberFormat from '../../../../../domain/Utils/NumberFormat'
import RowsPerPageSelector from '../RowsPerPageSelector'
import HeaderRight from '../Element/HeaderRight'
import { SortInterface, SortOrder } from "../../../../../domain/Utils/List"
import { useInvestorDataLoader } from '../../../customHook/distribution/useInvestorDataLoader'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import DistributionSessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import { ListRequest } from '../../../../../useCase/Distribution/Session/Investor/ListRequest'
import ListUseCase from '../../../../../useCase/Distribution/Session/Investor/ListUseCase'
import SessionInvestorListPresenter from '../../../../../presenter/Distribution/SessionInvestorListPresenter'
import { Link } from 'react-router-dom'
import iconSearch from '../../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import { ValidationErrorInterface } from '../../../../../domain/Error/Error'
import FormFieldErrors from '../../Alert/FormFieldErrors/FormFieldErrors'
import ReactTooltip from 'react-tooltip'
import { getLabelByValue } from '../../../util/ReferentialI18n'

type Props = {
  sessionId: string
  formErrors: ValidationErrorInterface|null
  isLectureMode: boolean
}

const InvestorDatalist: FunctionComponent<Props> = ({ sessionId, formErrors, isLectureMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const referential = useAppSelector((state) => state.referential.referential) as ReferentielInterface | null
  const filterState = useAppSelector((state) => state.event.openDatalistFilterDistributionSessionInvestor)
  const [sortOrder, setSortOrder] = useState<SortInterface>({ sortLabel: null, sortOrder: SortOrder.ASC })
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingExport, setLoadingExport] = useState(false)

  const methods = useForm()
  const { register, handleSubmit, setValue } = methods
  const preferences = localStorage.getItem('preferences')
  const numberRows = preferences ? JSON.parse(preferences).numberRows : 50
  const customerRules = useAppSelector((state) => state.me.me?.rules.customer.actions)

  const dataLoaderParams = useMemo(() => ({
    sessionId,
    currentPage,
    numberRows,
    filters: filterState.filters,
    sortOrder,
  }), [sessionId, currentPage, numberRows, filterState.filters, sortOrder])

  const { viewModel, setViewModel, loading } = useInvestorDataLoader(dataLoaderParams)

  const resetFilterState = () => {
    setValue('keyword', '')
    setValue('product', { id: '', value: '', label: '' })
    setValue('term', '')
    setValue('periodicity', '')
    setValue('paymentMethod', '')
  }
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleRowsChange = useCallback((rows: number) => {
    setValue('numberRows', rows)
    setCurrentPage(1)
  }, [setValue])

  const onSubmit: SubmitHandler<FilterSessionInvestorInterface> = (data) => {
    dispatch(
      setOpenDatalistFilterDistributionSessionInvestor({
        show: false,
        count: filterState.count,
        filters: {
          keyword: data.keyword,
          product: filterState.filters.product,
          term: filterState.filters.term,
          periodicity: filterState.filters.periodicity,
          paymentMethod: filterState.filters.paymentMethod,
        },
      })
    )
  }

  const paginate = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber)
  }, [])

  const handleExport = useCallback(() => {
    setLoadingExport(true)
    new DistributionSessionGateway().investorListExport(sessionId, filterState.filters).then(response => {
      if (response) {
        downloadBlobFile(response, t('export.distributions-investors'))
      }
    }).finally(() => setLoadingExport(false))
  }, [filterState])

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterDistributionSessionInvestor({show: true, count: filterState.count, filters: filterState.filters}))
    }
  }

  const getClassNameForItem = (item: SessionInvestorListInterface) : string => {
    const errors = item.subscriber?.errors
    if (!errors) {
      return 'tooltip-trigger'
    }

    if (errors.includes('no-iban')) {
      return 'tooltip-trigger line--error'
    }

    return errors.filter((err) => err != 'no-iban').length > 0 ? 'tooltip-trigger line--warning' : 'tooltip-trigger';
  }

  const getTooltip = (item: SessionInvestorListInterface) : string => {
    if (!item.subscriber?.errors?.length) {
      return '';
    }
    let tips = "<div class='multiline-tooltip' key='"+item.accountId+"-tip'>";
    item.subscriber.errors.map((err) => {
      tips += '<span>'+t(`validation.distribution.${err}`)+'</span>'
    })
    tips += '</div>'
    return tips;
  }

  return (
    <>
      {loading ? (
        <div>{t('common.loading')}</div>
      ) : (
        viewModel && (
          <>
            <div className="datalist">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="datalist__title">{t(viewModel.title)}</div>
                  <div className="datalist__header flex">
                    <div className="w-full flex justify-between items-center">
                      <div className="filter__input">
                        {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                          <div key={uuidV4()} className="input-no-border">
                            <img src={iconHeadingSearch} alt="" />
                            <input
                              {...register(filter.field)}
                              placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
                              className="u-mxs"
                            />
                          </div>
                        ))}
                      </div>
                      <div className="filter__actions flex items-center justify-end">
                        <button type="submit" className="button button--submit">
                          {t('search.submit')}
                        </button>
                        <button type="button" className="button button--white" onClick={resetFilterState}>
                          {t('search.cancel')}
                        </button>
                      </div>
                    </div>
                    <HeaderRight
                      numberOfActivatedFilters={filterState.count}
                      handleClickFilter={handleClickFilter}
                      handleClickExport={handleExport}
                      isLoadingExport={isLoadingExport}
                      allowExport
                      hideFilter={false}
                    />
                  </div>
                  <RowsPerPageSelector onRowsChange={handleRowsChange} />
                </form>
              </FormProvider>
              <div className="col-md-12">
                <FormFieldErrors errors={formErrors?.errors ?? null} title={formErrors?.title ?? t('distribution.form.product.form-error-base-message')}/>
              </div>
              <div className="table-fix-head">
                <ReactTooltip html={true}/>
                <table className="datalist__datas">
                  <thead>

                  {viewModel.heading && (
                    <TableHead
                      typeFilter="API"
                      heading={viewModel.heading}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      viewModel={viewModel}
                      setViewModel={setViewModel}
                      filter={filterState.filters}
                      investorId={sessionId}
                      watchNumberRows={numberRows}
                      currentPage={currentPage}
                      listRequest={
                        new ListRequest(
                          currentPage,
                          numberRows || 50,
                          filterState.filters,
                          sessionId
                        )
                      }
                      gateway={DistributionSessionGateway}
                      listUseCase={ListUseCase}
                      listPresenter={SessionInvestorListPresenter}
                    />
                  )}
                  </thead>
                  <tbody>
                  {viewModel.data.length > 0 ? (
                    viewModel.data.map((item: SessionInvestorListInterface) => (
                      <>
                      <tr key={uuidV4()} className={getClassNameForItem(item)} data-tip={getTooltip(item)}>
                        <td>
                          {customerRules?.read &&
                            <Link to={`/${t('url.customer.read-bank-details')}/${item.accountId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                            >
                              <button type="button" className="button-reset">
                                <img src={iconSearch} alt=""/>
                              </button>
                            </Link>}
                          {customerRules?.update &&
                            <Link to={`/${t('url.customer.edit-bank-details')}/${item.accountId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button type="button" className="button-reset">
                                <img src={iconEdit} alt=""/>
                              </button>
                            </Link>}
                        </td>

                        <td>{item.product.label}</td>
                        <td>{referential?.product.periodicity.find(option => option.value === item.product.periodicity)?.label}</td>
                        <td>{referential?.product.distribution_term.find(option => option.value === item.product.term)?.label}</td>
                        <td>{item.product.ribTitle}</td>
                        <td>{item.subscriber?.accountCode}</td>
                        <td>{item.subscriber?.legalName}</td>
                        <td>{item.coSubscriber?.legalName}</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.totalNbShare, false, 0)}</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.financialAmount)}€</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.propertyAmount)}€</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.pl)}€</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.ps)}€</td>
                        <td className={`align-right`}>{NumberFormat.currencyFormat(item.amount)}€</td>
                        <td className={`align-center`}>{item.paymentDate}</td>
                        <td className={`align-center`}>{getLabelByValue(item.paymentMethod ?? '', referential?.prospect?.payment_method ?? [])}</td>
                        <td>{item.subscriber?.iban}</td>
                        <td className={`align-center`}>{getLabelByValue(item.paymentState ?? '', referential?.distribution?.payment_status ?? [])}</td>
                        <td className={`align-center`}>{getLabelByValue(item.sendMode ?? '', referential?.distribution?.send_mode ?? [])}</td>
                        <td className={`align-center`}>{item.sendDate}</td>
                        <td className={`align-center`}>{getLabelByValue(item.sendState ?? '', referential?.distribution?.send_status ?? [])}</td>
                        <td>{item.subscriber.addressFormatted}</td>
                        <td>{item.coSubscriber?.addressFormatted}</td>
                        <td>{item.subscriber?.email}</td>
                        <td>{item.coSubscriber?.email}</td>
                      </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={viewModel.heading.length}>{t('common.data-is-empty')}</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={numberRows || viewModel.pagination.itemsPerPage}
                numberOfItems={viewModel.pagination.numberOfItems}
                callback={paginate}
              />
            </div>
          </>
        )
      )}
    </>
  )
}

export default React.memo(InvestorDatalist)
