import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {v4 as uuidV4} from "uuid";
import iconChevron from "../../../../assets/images/icons/chevron.svg";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import iconChevronSelected from "../../../../assets/images/icons/chevronSelected.svg";
import {useTranslation} from "react-i18next";
import {setCountTotal} from "../../../store/component/movement";
import {useAppDispatch} from "../../../store/hook";
import {CallerType} from "../../../../domain/Caller/Caller";

type Props = {
  typeFilter: "API" | "FRONT"
  heading: any[]
  sortOrder: SortInterface
  setSortOrder: Dispatch<SetStateAction<SortInterface>>
  viewModel: any
  setViewModel: any
  filter?: any
  listRequest?: any
  listUseCase?: any
  listPresenter?: any
  gateway?: any
  currentPage?: number
  watchNumberRows?: any
  showSelector?: boolean,
  selectorValue?: boolean,
  onSelectorChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  subObjectProperty?: string
  investorId?: string
  callerType?: CallerType
}

const TableHead: FunctionComponent<Props> = ({
                                               typeFilter,
                                               heading,
                                               sortOrder,
                                               setSortOrder,
                                               viewModel,
                                               setViewModel,
                                               subObjectProperty,
                                               filter,
                                               investorId,
                                               listPresenter,
                                               listUseCase,
                                               listRequest,
                                               gateway,
                                               currentPage,
                                               watchNumberRows,
                                               showSelector,
                                               onSelectorChange,
                                               selectorValue = false,
                                               callerType
                                             }) => {
  const {t} = useTranslation()
  const controller = new AbortController()
  const dispatch = useAppDispatch()

  function sortData(sort: string | undefined) {
    if (sort) {
      const defineNextOrder = sortOrder.sortLabel !== sort ? SortOrder.ASC : sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineNextOrder
      };
      setSortOrder(order);

      const data = new listUseCase(new gateway())
        .execute(listRequest, controller.signal, sort, order.sortOrder, callerType)
        .then((response: any) => {
          return response
        })


      const presenter = new listPresenter(data)
      presenter
        .load()
        .then(() => {
          setViewModel(presenter.immutableViewModel())
          dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
    }
  }

  const sortDynamic = (key: string, order = SortOrder.ASC) => {
    const sortOrder = order === SortOrder.ASC ? 1 : -1

    return (a: any, b: any) => {
      const targetPropertyA = subObjectProperty ? a[subObjectProperty][key] : a[key]
      const targetPropertyB = subObjectProperty ? b[subObjectProperty][key] : b[key]

      const A = (typeof a[key] === 'string') ? targetPropertyA.toUpperCase() : targetPropertyA;
      const B = (typeof b[key] === 'string') ? targetPropertyB.toUpperCase() : targetPropertyB;
      if (A < B) {
        return sortOrder * -1
      } else if (A > B) {
        return sortOrder * 1
      } else {
        return 0
      }
    }
  }

  const sortDataFront = (sortLabel: string, sortLabelApi: string) => {
    if (sortLabel && viewModel.data) {
      const defineNextOrder = sortLabelApi !== sortOrder.sortLabelApi ? SortOrder.ASC : sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      const order: SortInterface = {
        sortLabel: sortLabel,
        sortLabelApi: sortLabelApi,
        sortOrder: defineNextOrder
      };
      setSortOrder(order)

      let dataSorted = [...viewModel.data]

      dataSorted = dataSorted.sort(sortDynamic(sortLabelApi, defineNextOrder))

      setViewModel({...viewModel, data: dataSorted})

    }
  }

  return (
    <tr>
      {showSelector && <th>
          <div style={{paddingLeft: '5px'}}>
              <input
                  type={"checkbox"}
                  checked={selectorValue}
                  onChange={onSelectorChange}
              />
          </div>
      </th>}
      {heading.map((title: { name: string, sort?: string, labelApi: string }) => (
        <th key={uuidV4()}
            className={title.sort ? 'sortable' : ''}
            onClick={() => typeFilter === "FRONT"
              ? sortDataFront(title?.sort || "", title.labelApi)
              : sortData(title?.sort)

            }
        >
          {t(title.name)}
          {title.sort && sortOrder.sortLabel !== title.sort &&
              <div className="chevrons">
                  <img src={iconChevron} className="chevron-top" alt="chevron-top"/>
                  <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom"/>
              </div>
          }
          {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
              <div className="chevrons">
                  <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom"/>
              </div>
          }
          {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
              <div className="chevrons">
                  <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top"/>
              </div>
          }
        </th>
      ))}
    </tr>
  );
}

export default TableHead;
