import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import {Link} from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/recurrence'
import {setOpenDatalistFilterRecurrence} from '../../../store/component/event'
import {FilterRecurrenceInterface} from '../../../../domain/Movement/RecurrenceList'
import RecurrenceListPresenter from '../../../../presenter/Movement/RecurrenceListPresenter'
import {ListRequest} from '../../../../useCase/Recurrence/List/ListRequest'
import ListUseCase from '../../../../useCase/Recurrence/List/ListUseCase'
import Pagination from '../Pagination/Pagination'
import RecurrenceGateway from '../../../../gateway/Movement/RecurrenceGateway'
import download from '../../util/Download'
import HeaderRight from './Element/HeaderRight'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {MovementListInterface} from "../../../../domain/Movement/MovementList";
import MovementUtil from '../../util/MovementUtil';
import ProductDalalist from "./Product/ProductDalalist";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import TableHead from "../Table/TableHead";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const RecurrenceDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterRecurrence = useAppSelector((state) => state.event.openDatalistFilterRecurrence)
  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const { control, register, handleSubmit, setValue, watch, getValues } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(setOpenDatalistFilterRecurrence( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterRecurrence.filters)
      const recurrences = new ListUseCase(new RecurrenceGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      });
      const presenter = new RecurrenceListPresenter(recurrences);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('product', openDatalistFilterRecurrence.filters.product)
      setValue('transactionType', openDatalistFilterRecurrence.filters.transactionType)
      setValue('status', openDatalistFilterRecurrence.filters.status)
      setValue('tags', openDatalistFilterRecurrence.filters.tags)
      setValue('propertyType', openDatalistFilterRecurrence.filters.propertyType)
      setValue('paymentMode', openDatalistFilterRecurrence.filters.paymentMode)
      setValue('user', openDatalistFilterRecurrence.filters.user)
    }
  }, [currentPage, openDatalistFilterRecurrence.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterRecurrence.filters])

  const onSubmit: SubmitHandler<FilterRecurrenceInterface> = data => {
    dispatch(setOpenDatalistFilterRecurrence({
        show: false,
        count: openDatalistFilterRecurrence.count,
        filters: {
          product: data.product,
          transactionType: data.transactionType,
          status: data.status,
          tags: data.tags,
          propertyType: data.propertyType,
          paymentMode: data.paymentMode,
          user: data.user,
          name: data.name,
          keywords: data.keywords
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterRecurrence({show: true, count: openDatalistFilterRecurrence.count, filters: openDatalistFilterRecurrence.filters}))
    }
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterRecurrence( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new RecurrenceGateway().getExport(openDatalistFilterRecurrence.filters).then(response => {
      if (response) {
        download(t('export.recurrences'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
              <ProductDalalist classes="form-control__input  form-control__max-content"
                               id="product"
                               name="product"
                               control={control}
                               defaultValue={getValues('product')}
                               customPlaceHolder={t('common.search-by', {keyword:t('filters.by-product')})}
                               maxContentFit
              />
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
            </div>
          </form>
          <HeaderRight numberOfActivatedFilters={openDatalistFilterRecurrence.count}
                       handleClickFilter={handleClickFilter}
                       handleClickExport={handleClickExport}
                       isLoadingExport={isLoadingExport}
                       allowExport={transactionRules?.export}
          />
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
        <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          {viewModel.heading && <TableHead typeFilter={"API"}
                                           heading={viewModel.heading}
                                           sortOrder={sortOrder}
                                           setSortOrder={setSortOrder}
                                           viewModel={viewModel}
                                           setViewModel={setViewModel}
                                           filter={openDatalistFilterRecurrence.filters}
                                           watchNumberRows={watchNumberRows}
                                           currentPage={currentPage}
                                           listRequest={
                                             new ListRequest(
                                               currentPage,
                                               watchNumberRows || 50,
                                               openDatalistFilterRecurrence.filters
                                             )
                                           }
                                           listUseCase={ListUseCase}
                                           listPresenter={RecurrenceListPresenter}
                                           gateway={RecurrenceGateway}
          />}
          </thead>
          <tbody>
          {viewModel?.data.map((item: MovementListInterface) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                    <td>
                      {transactionRules?.read && <Link to={`/${t('url.pre-subscription.read')}/${item.id}`}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                          <button type="button" className="button-reset">
                              <img src={iconSearch} alt=""/>
                          </button>
                      </Link>}
                      {transactionRules?.update && <Link to={`/${t('url.pre-subscription.edit')}/${item.id}`}>
                          <button type="button" className="button-reset">
                              <img src={iconEdit} alt=""/>
                          </button>
                      </Link>}
                    </td>
                    <td>{item.product_label}</td>
                    <td>{item.transaction_type}</td>
                    <td>{item.code}</td>
                    <td>{item.engagedAt}</td>
                    <td>{item.status_label}</td>
                    <td>{item.property_type}</td>
                    <td>{item.investor_id}</td>
                    <td>{item.investor_name}</td>
                    <td>{item.partner_code}</td>
                    <td>{item.partner_name}</td>
                    <td>{item.user?.firstname?.toUpperCase().slice(0, 1)}. {item.user?.lastname?.toUpperCase()}</td>
                    <td>
                      {
                        referential?.wallet_subscription.periodicity
                        .find(property => property.value === item.periodicity)
                        ?.label.toString()
                      }
                    </td>
                    <td>{item.payment_method}</td>
                    <td>{item.payment_status}</td>
                    <td>{item.payment_date}</td>
                    <td>{item.share_count}</td>
                    <td>{item.total_amount}</td>
                    <td>
                        <div className="u-mys">
                          {item.tags && item.tags.map(tag => {
                            const key = `movement.tags.${tag}`
                            const type = MovementUtil.getType(tag)
                            return <div className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`} key={uuidV4()}>
                              <div className="badge__container">
                                <div className="badge__text">{t(key)}</div>
                              </div>
                            </div>
                          })}
                        </div>
                    </td>
                </>
              }
            </tr>
          ))}
          {viewModel?.data.length === 0 &&
            <tr>
              <td colSpan={ viewModel?.heading.length }>{ t('common.data-is-empty') }</td>
            </tr>
          }
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default RecurrenceDatalist
