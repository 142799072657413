import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'

import { FilterDismembermentLedgerInterface } from '../../../../../domain/DismembermentLedger/DismembermentLedgerList'
import { setOpenDatalistFilterDismembermentLedger } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { Filter } from '../../../../../presenter/Presenter'

import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import { DismembermentLedgerType } from '../../../../../domain/DismembermentLedger/DismembermentLedgerGatewayInterface'

type FilterFormProps = {
  filters: Filter[]
  type: DismembermentLedgerType
}

const FilterForm: FunctionComponent<FilterFormProps> = ({ filters, type }) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { register, handleSubmit, reset, getValues } = useFormContext()

  const datalistFilter = useAppSelector((state) => state.event.openDatalistFilterDismembermentLedger)

  const onSubmit: SubmitHandler<FilterDismembermentLedgerInterface> = data => {
    dispatch(setOpenDatalistFilterDismembermentLedger({
      show: false,
      count: datalistFilter.count,
      type: type,
      filters: {
        product: data.product,
        executions: data.executions,
        typeDurations: data.typeDurations,
        preferences: data.preferences,
        keywords: {
          [type] : data.keywords ?? '',
        },
      }
    }))
  }

  const resetFilters = () => {
    reset()
    dispatch(setOpenDatalistFilterDismembermentLedger({
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        executions: [],
        typeDurations: [],
        preferences: [],
        keywords: {
          'bare-owners' : '',
          'usufructuaries' : '',
        }
      }
    }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='filter'>
      <div className='filter__input'>
        {filters.map((filter: Filter) => (
          <div key={uuidV4()} className='input-no-border'>
            <img src={iconHeadingSearch} alt='' />
            <input
              {...register(filter.field)}
              placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
              className='u-mxs'
            />
          </div>
        ))}
      </div>
      <div className='filter__actions'>
        <button type='submit' className='button button--submit'>
          {t('search.submit')}
        </button>
        <button type='button' className='button button--white' onClick={resetFilters}>
          {t('search.cancel')}
        </button>
      </div>
    </form>
  )
};

export default FilterForm;
