import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import {Link} from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setCountTotal} from '../../../../store/component/recurrence'
import {setOpenDatalistFilterDistributionSession} from '../../../../store/component/event'
import {DistributionSessionListInterface, FilterDistributionSessionInterface} from '../../../../../domain/Distribution'
import {ListRequest} from '../../../../../useCase/Distribution/Session/ListRequest'
import ListUseCase from '../../../../../useCase/Distribution/Session/ListUseCase'
import Pagination from '../../Pagination/Pagination'

import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import {optionsNbRows} from "../../../../../fixtures/Referentiel";
import SelectCustom from "../../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../../util/SavePreferencesInLocalStorage";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import SessionListPresenter from '../../../../../presenter/Distribution/SessionListPresenter'
import SessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import {getLabelByValue} from '../../../util/ReferentialI18n'
import {ViewModelInterface} from '../../../../type'

const SessionDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterDistributionSession = useAppSelector((state) => state.event.openDatalistFilterDistributionSession)
  const rules = useAppSelector((state) => state.me.me?.rules.distribution.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const { control, register, handleSubmit, setValue, watch, getValues } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(setOpenDatalistFilterDistributionSession( {
      show: false,
      count: 0,
      filters: {
        keyword: '',
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterDistributionSession.filters)
      const sessions = new ListUseCase(new SessionGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      });

      const presenter = new SessionListPresenter(sessions);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('keyword', openDatalistFilterDistributionSession.filters.keyword)
    }
  }, [currentPage, openDatalistFilterDistributionSession.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterDistributionSession.filters])

  const onSubmit: SubmitHandler<FilterDistributionSessionInterface> = data => {
    dispatch(setOpenDatalistFilterDistributionSession({
        show: false,
        count: openDatalistFilterDistributionSession.count,
        filters: {
          keyword: data.keyword,
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterDistributionSession( {
      show: false,
      count: 0,
      filters: {
        keyword: '',
      }
    }))
  }

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header flex">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full flex justify-between items-center">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                </div>
                <div className="filter__actions flex items-center justify-end">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                </div>
              </form>
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="table-fix-head">
              <table className="datalist__datas">
                <thead>
                {viewModel.heading && <TableHead typeFilter={"API"}
                                                 heading={viewModel.heading}
                                                 sortOrder={sortOrder}
                                                 setSortOrder={setSortOrder}
                                                 viewModel={viewModel}
                                                 setViewModel={setViewModel}
                                                 filter={openDatalistFilterDistributionSession.filters}
                                                 watchNumberRows={watchNumberRows}
                                                 currentPage={currentPage}
                                                 listRequest={
                                                   new ListRequest(
                                                     currentPage,
                                                     watchNumberRows || 50,
                                                     openDatalistFilterDistributionSession.filters
                                                   )
                                                 }
                                                 listUseCase={ListUseCase}
                                                 listPresenter={SessionListPresenter}
                                                 gateway={SessionGateway}
                />}
                </thead>
                <tbody>
                {viewModel?.data?.map((item: DistributionSessionListInterface) => (
                  <tr key={uuidV4()}>
                    {item !== null && item !== undefined &&
                      <>
                        <td>
                          {rules?.read &&
                            <Link to={`/${t('url.distribution.session.read-setting')}/${item.type}/${item.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                            >
                              <button type="button" className="button-reset">
                                <img src={iconSearch} alt=""/>
                              </button>
                            </Link>}
                          {rules?.update &&
                            <Link to={`/${t('url.distribution.session.edit-setting')}/${item.type}/${item.id}`}>
                              <button type="button" className="button-reset">
                                <img src={iconEdit} alt=""/>
                              </button>
                            </Link>}
                        </td>
                        <td>
                          {item.products.map((product) => (
                            <div className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`} key={`${item.label}-${product.id}`}>
                              <div className="badge__container">
                                <div className="badge__text">{product.label}</div>
                              </div>
                            </div>
                          ))}
                        </td>
                        <td>
                          <div className="u-mys">
                            {item.label}
                          </div>
                        </td>
                        <td>
                          <div className={`badge etat etat--${item.status} badge--shadow u-mrs`}>
                            <div
                              className="badge__text etat-small">{getLabelByValue(item.type ?? '', referential?.distribution.session_type ?? [])}</div>
                          </div>
                        </td>
                        <td>
                          <div className={`badge etat etat--${item.status} badge--shadow u-mrs`}>
                            <div className="badge__text etat-small">{getLabelByValue(item.status ?? '', referential?.distribution.session_status ?? [])}</div>
                          </div>
                        </td>
                        <td>{item.calculeAt}</td>
                        <td>{item.validateAt}</td>
                        <td>{item.paidAt}</td>
                        <td>{item.sendAt}</td>
                      </>
                    }
                  </tr>
                ))}
                {viewModel?.data?.length === 0 &&
                  <tr>
                    <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default SessionDatalist
